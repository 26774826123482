import { Box, Typography } from '@material-ui/core'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

import { useMobileInputFieldsStyles } from './hooks'

import { Icon } from '../../../Icon'

export const MobileInputFields = ({
  consultationInputValue,
  locationInputValue,
  onClick,
  className
}) => {
  const { joinedContainerClasses, classes } = useMobileInputFieldsStyles({ className })

  return (
    <Box className={joinedContainerClasses} onClick={onClick}>
      <Box className={classes.inputValuesContainer}>
        <Typography className={classes.consultationInputText}>
          {consultationInputValue || 'Any consultation'}
        </Typography>
        <Typography className={classes.locationInputText}>
          {locationInputValue || 'Melbourne, Victoria'}
        </Typography>
      </Box>
      <Icon icon={faMagnifyingGlass} className={classes.magnifyingGlassIcon} />
    </Box>
  )
}
