import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { InstantSearch } from 'react-instantsearch'
import { useDispatch, useSelector } from 'react-redux'

import { useMobileSearchModalStyles } from './hooks'

import { Button } from '../../../Button'
import { Icon } from '../../../Icon'
import {
  ConsultationInputField,
  ConsultationResultList,
  LocationInputField,
  LocationResultList
} from '../../../InputField'

import {
  selectConsultationFilterOption,
  setConsultationFilterOption
} from '../../../../../containers/mui/SearchPage/duck'
import { client as algoliaClient } from '../../../../../lib/algolia'
import { getLocationsByName } from '../../../../../util/new/geo'

export const MobileSearchModal = ({
  isOpen,
  onClose,
  consultationInputValue,
  setConsultationInputValue,
  locationInputValue,
  setLocationInputValue,
  onConsultationInputChange,
  onLocationInputChange,
  onSearchClick,
  onClearClick,
  onUseMyLocationClick,
  isFetchLocationError,
  locationResults,
  setLocationResults
}) => {
  const dispatch = useDispatch()
  const consultationFilterOption = useSelector(selectConsultationFilterOption)
  const [consultationResults, setConsultationResults] = useState({
    consultation: {
      hits: []
    },
    listing: {
      hits: []
    }
  })
  const [isConsultationResultsRendered, setIsConsultationResultsRendered] = useState(false)
  const [isLocationResultsRendered, setIsLocationResultsRendered] = useState(false)
  const { classes } = useMobileSearchModalStyles()

  const consultationResultItemClickHandler = (value) => {
    setConsultationInputValue(value.name)
    setIsConsultationResultsRendered(false)
    dispatch(setConsultationFilterOption(value))
  }

  const locationResultItemClickHandler = (value) => {
    setLocationInputValue(value)
    setIsLocationResultsRendered(false)
  }

  const useMyLocationButtonClickHandler = async () => {
    await onUseMyLocationClick()
  }

  const consultationFocusHandler = () => {
    setIsConsultationResultsRendered(true)
  }

  const locationFocusHandler = () => {
    setIsLocationResultsRendered(true)
  }

  const consultationBlurHandler = (value) => {
    setIsConsultationResultsRendered(false)

    if (value.length === 0) {
      setConsultationInputValue(consultationFilterOption.name)
    }
  }

  const locationBlurHandler = () => {
    if (locationInputValue.length === 0) {
      setLocationInputValue('Melbourne, Victoria')
    }
    setIsLocationResultsRendered(false)
  }

  const mouseDownHandler = (event) => {
    event.preventDefault()
  }

  const getLocations = async (value) => {
    const locations = await getLocationsByName(value)
    setLocationResults(locations)
  }

  const locationInputChangeHandler = async (value) => {
    if (onLocationInputChange) {
      onLocationInputChange(value)
    }

    if (value !== '') {
      getLocations(value)
    }
  }

  const consultationInputChangeHandler = (value) => {
    onConsultationInputChange(value)
    setIsConsultationResultsRendered(true)
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen scroll={'paper'}>
      <DialogTitle>
        <Box className={classes.headerContainer}>
          <Icon icon={faArrowLeft} className={classes.leftArrowIcon} onClick={onClose} />
          <Typography className={classes.headerText}>Search</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box className={classes.searchSectionsContainer}>
          <Box className={classes.consultationSearchSectionContainer}>
            <InstantSearch searchClient={algoliaClient} indexName="Consultation">
              <ConsultationInputField
                value={consultationInputValue}
                placeholder="Search"
                setValue={setConsultationInputValue}
                className={classes.inputFieldContainer}
                onChange={consultationInputChangeHandler}
                onFocus={consultationFocusHandler}
                onBlur={consultationBlurHandler}
                setConsultationResults={setConsultationResults}
                fullWidth
              />
              {isConsultationResultsRendered && (
                <ConsultationResultList
                  platform="mobile"
                  consultationInputValue={consultationInputValue}
                  onItemClick={consultationResultItemClickHandler}
                  onMouseDown={mouseDownHandler}
                  results={consultationResults}
                />
              )}
            </InstantSearch>
          </Box>
          <Box className={classes.locationSearchSectionContainer}>
            <LocationInputField
              value={locationInputValue}
              setValue={setLocationInputValue}
              className={classes.inputFieldContainer}
              onChange={locationInputChangeHandler}
              onFocus={locationFocusHandler}
              onBlur={locationBlurHandler}
              fullWidth
            />
            {isLocationResultsRendered && (
              <LocationResultList
                results={locationResults}
                locationInputValue={locationInputValue}
                onItemClick={locationResultItemClickHandler}
                onUseMyLocationClick={useMyLocationButtonClickHandler}
                isFetchLocationError={isFetchLocationError}
                onMouseDown={mouseDownHandler}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.buttonsContainer}>
          <Button uiType="secondary" className={classes.button} onClick={onClearClick}>
            Clear
          </Button>
          <Button
            uiType="primary"
            className={classes.button}
            onClick={() => {
              onSearchClick({ consultationInputValue, locationInputValue })
              onClose()
            }}
          >
            Search
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
