import { client as mapboxClient } from '../../lib/mapbox'

export const getCurrentUserLocation = async () => {
  if (!navigator.geolocation) {
    console.error('Geolocation is not supported by this browser.')
    return undefined
  }

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })
  }

  try {
    const position = await getLocation()
    return position.coords
  } catch (error) {
    console.error('Error Code = ' + error.code + ' - ' + error.message)
  }
}

export const getLocationsByName = async (value) => {
  try {
    const response = await mapboxClient
      .forwardGeocode({
        query: value,
        limit: 5,
        countries: ['AU']
      })
      .send()

    return response.body.features
  } catch (error) {
    console.error('Error fetching results from Mapbox:', error)
  }
}
