import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  DesktopInputFields,
  MobileInputFields,
  MobileSearchModal,
  ViewToggleButtons
} from './components'
import { usePractitionerSearchSectionStyles } from './hooks'

import {
  clearFilterOptions,
  fetchPractitionerListings,
  selectConsultationFilterOption,
  selectLocationFilterOption,
  setLocationFilterOption
} from '../../../containers/mui/SearchPage/duck'
import { getCurrentUserLocation } from '../../../util/new/geo'

export const PractitionerSearchSection = ({ setCurrentView }) => {
  const dispatch = useDispatch()
  const location = useSelector(selectLocationFilterOption)
  const consultation = useSelector(selectConsultationFilterOption)

  const [consultationInputValue, setConsultationInputValue] = useState(consultation.name)
  const [locationInputValue, setLocationInputValue] = useState(location.name)
  const [isFetchLocationError, setIsFetchLocationError] = useState(false)
  const [userLocation, setUserLocation] = useState(undefined)
  const [locationResults, setLocationResults] = useState([])
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)
  const { classes } = usePractitionerSearchSectionStyles()

  useEffect(() => {
    setConsultationInputValue(consultation.name)
  }, [consultation])

  useEffect(() => {
    searchClickHandler({ location, consultation })
  }, [consultation, location])

  useEffect(() => {
    if (userLocation) {
      const { latitude, longitude } = userLocation
      setLocationInputValue('Near me')
      dispatch(setLocationFilterOption({ name: 'Near me', lat: latitude, lon: longitude }))
    } else {
      dispatch(
        setLocationFilterOption({ name: 'Melbourne, Victoria', lat: -37.81398, lon: 144.96457 })
      )
    }
  }, [userLocation])

  const useMyLocationClickHandler = async () => {
    const location = await getCurrentUserLocation()

    if (!location) {
      setIsFetchLocationError(true)
      console.error('Failed to get user location')
      return
    }

    const { latitude, longitude } = location
    setUserLocation({ latitude, longitude })

    return location
  }

  const locationInputChangeHandler = (value) => {
    setLocationInputValue(value)
  }

  const clearInputHandler = () => {
    setLocationInputValue('Melbourne, Victoria')
    dispatch(clearFilterOptions())
  }

  const searchClickHandler = () => {
    dispatch(fetchPractitionerListings())
  }

  const modalClickHandler = () => {
    setIsSearchModalOpen(true)
  }

  const modalCloseHandler = () => {
    setIsSearchModalOpen(false)
  }

  const listViewClickHandler = () => {
    setCurrentView('list')
  }

  const mapViewClickHandler = () => {
    setCurrentView('map')
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.searchElementsContainer}>
          <DesktopInputFields
            consultationInputValue={consultationInputValue}
            setConsultationInputValue={setConsultationInputValue}
            onConsultationInputChange={setConsultationInputValue}
            locationInputValue={locationInputValue}
            setLocationInputValue={setLocationInputValue}
            onLocationInputChange={locationInputChangeHandler}
            onUseMyLocationClick={useMyLocationClickHandler}
            isFetchLocationError={isFetchLocationError}
            locationResults={locationResults}
            setLocationResults={setLocationResults}
          />
          <MobileInputFields
            onClick={modalClickHandler}
            consultationInputValue={consultation.name}
            locationInputValue={locationInputValue}
            onMobileSearchModalOpen={modalClickHandler}
          />
        </Box>
        <ViewToggleButtons
          onLeftButtonClick={listViewClickHandler}
          onRightButtonClick={mapViewClickHandler}
        />
      </Box>
      <MobileSearchModal
        isOpen={isSearchModalOpen}
        onClose={modalCloseHandler}
        consultationInputValue={consultationInputValue}
        setConsultationInputValue={setConsultationInputValue}
        onConsultationInputChange={setConsultationInputValue}
        locationInputValue={locationInputValue}
        setLocationInputValue={setLocationInputValue}
        onLocationInputChange={locationInputChangeHandler}
        onSearchClick={searchClickHandler}
        onClearClick={clearInputHandler}
        onUseMyLocationClick={useMyLocationClickHandler}
        isFetchLocationError={isFetchLocationError}
        locationResults={locationResults}
        setLocationResults={setLocationResults}
      />
    </>
  )
}
