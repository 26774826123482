import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints } = theme

  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      [breakpoints.down('sm')]: {
        justifyContent: 'normal',
        gap: '16px',
        width: '100%'
      }
    },
    searchElementsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      width: '100%'
    },
    searchButton: {
      width: '94px',
      height: '42px',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  }
})

export const usePractitionerSearchSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
