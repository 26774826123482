import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints } = theme

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    resultListContainer: {
      position: 'absolute',
      marginTop: '8px',
      top: '100%',
      left: 0,
      right: 0,
      zIndex: 900,
      maxHeight: '600px',
      overflowY: 'auto',
      padding: '20px',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  }
})

export const useDesktopInputFieldsStyles = () => {
  const classes = useStyles()
  return { classes }
}
