import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      gap: '32px',
      padding: '20px',
      backgroundColor: palette.background.paper,
      borderRadius: '4px',
      minHeight: '490px',
      [breakpoints.down('sm')]: {
        gap: '24px'
      },
      [breakpoints.down('xs')]: {
        padding: '16px'
      }
    },
    listingsSectionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    }
  }
})

export const usePractitionerListingsSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
