import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '42px',
      height: '42px',
      borderRadius: '4px',
      border: 'none',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.grey['200']
      },
      [breakpoints.down('sm')]: {
        width: '56px',
        height: '56px'
      }
    },
    buttonContainerActive: {
      backgroundColor: palette.grey['200']
    },
    buttonIcon: {
      width: '18px',
      height: '18px',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
})

export const useViewToggleButtonStyles = () => {
  const classes = useStyles()
  return { classes }
}
