import { useState } from 'react'
import classNames from 'classnames'
import { Box } from '@material-ui/core'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { faMap } from '@fortawesome/free-regular-svg-icons'

import { useViewToggleButtonStyles } from './hooks'

import { Icon } from '../../../Icon'

export const ViewToggleButtons = ({ onLeftButtonClick, onRightButtonClick }) => {
  const [activeButton, setActiveButton] = useState('left')
  const { classes } = useViewToggleButtonStyles({ activeButton })

  const leftButtonJoinedClasses = classNames(
    classes.buttonContainer,
    activeButton === 'left' && classes.buttonContainerActive
  )

  const rightButtonJoinedClasses = classNames(
    classes.buttonContainer,
    activeButton === 'right' && classes.buttonContainerActive
  )

  const leftButtonClickHandler = () => {
    setActiveButton('left')
    onLeftButtonClick()
  }

  const rightButtonClickHandler = () => {
    setActiveButton('right')
    onRightButtonClick()
  }

  return (
    <Box className={classes.container}>
      <button className={leftButtonJoinedClasses} onClick={leftButtonClickHandler}>
        <Icon icon={faList} className={classes.buttonIcon} />
      </button>
      <button className={rightButtonJoinedClasses} onClick={rightButtonClickHandler}>
        <Icon icon={faMap} className={classes.buttonIcon} />
      </button>
    </Box>
  )
}
