import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints } = theme

  return {
    container: {
      display: 'flex',
      width: '100%',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '91px',
      paddingBottom: '80px',
      [breakpoints.down('xs')]: {
        paddingRight: '16px',
        paddingLeft: '16px'
      }
    },
    listingsInnerContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      width: '100%',
      maxWidth: '1490px'
    },
    listingsOuterContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
})

export const useSearchPageStyles = () => {
  const classes = useStyles()
  return { classes }
}
