import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'none',
      '&:hover': {
        cursor: 'pointer'
      },
      [breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '12px',
        paddingRight: '12px',
        border: `1px solid ${palette.grey['350']}`,
        borderRadius: '4px',
        minWidth: '200px',
        width: '100%',
        height: '56px'
      }
    },
    inputValuesContainer: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      gap: '4px'
    },
    consultationInputText: {
      fontSize: '14px',
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0
    },
    locationInputText: {
      fontSize: '12px',
      color: palette.blue['300'],
      padding: 0,
      letterSpacing: 0
    },
    magnifyingGlassIcon: {
      width: '13px',
      height: '13px',
      color: palette.primary.main,
      flex: '0 0 auto',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
})

export const useMobileInputFieldsStyles = ({ className }) => {
  const classes = useStyles()
  const joinedContainerClasses = classNames(classes.container, className)

  return { joinedContainerClasses, classes }
}
