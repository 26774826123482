import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: '24px',
      backgroundColor: palette.background.paper
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    leftArrowIcon: {
      width: '42px',
      height: '42px',
      color: palette.blue['800'],
      '&:hover': {
        cursor: 'pointer'
      }
    },
    headerText: {
      fontSize: '20px',
      fontWeight: 500,
      color: palette.blue['800']
    },
    inputFieldContainer: {
      width: '100%',
      height: '52px'
    },
    locationSearchSectionContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    consultationSearchSectionContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    searchSectionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    },
    buttonsContainer: {
      bottom: 0,
      left: 0,
      width: '100%',
      display: 'flex',
      gap: '16px',
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
      backgroundColor: palette.background.paper
    },
    button: {
      flex: 1,
      height: '42px'
    }
  }
})

export const useMobileSearchModalStyles = () => {
  const classes = useStyles()
  return { classes }
}
