import { Box, CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { ListView, MapView, NoResultsBanner, SortFilterSection } from './components'
import { usePractitionerListingsSectionStyles } from './hooks'

import {
  selectFetchListingsInProgress,
  selectListings
} from '../../../containers/mui/SearchPage/duck'

export const PractitionerListingsSection = ({ currentView }) => {
  const { classes } = usePractitionerListingsSectionStyles()
  const listings = useSelector(selectListings)
  const isLoading = useSelector(selectFetchListingsInProgress)

  return (
    <Box className={classes.container}>
      <SortFilterSection view={currentView} />
      <Box className={classes.listingsSectionContainer}>
        {isLoading ? (
          <CircularProgress />
        ) : currentView === 'list' ? (
          listings.length > 0 ? (
            <ListView />
          ) : (
            <NoResultsBanner />
          )
        ) : (
          <MapView />
        )}
      </Box>
    </Box>
  )
}
