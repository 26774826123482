import { Box, Paper } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { InstantSearch } from 'react-instantsearch'
import { useDispatch, useSelector } from 'react-redux'

import { useDesktopInputFieldsStyles } from './hooks'

import {
  ConsultationInputField,
  ConsultationResultList,
  LocationInputField,
  LocationResultList
} from '../../../InputField'

import { client as algoliaClient } from '../../../../../lib/algolia'
import { getLocationsByName } from '../../../../../util/new/geo'

import { AnalyticsEvent, trackEvent } from '../../../../../analytics/handlers'
import {
  selectConsultationFilterOption,
  setConsultationFilterOption
} from '../../../../../containers/mui/SearchPage/duck'

export const DesktopInputFields = ({
  consultationInputValue,
  setConsultationInputValue,
  locationInputValue,
  setLocationInputValue,
  onConsultationInputChange,
  onLocationInputChange,
  onUseMyLocationClick,
  isFetchLocationError,
  locationResults,
  setLocationResults
}) => {
  const dispatch = useDispatch()
  const consultationFilterOption = useSelector(selectConsultationFilterOption)
  const [consultationResults, setConsultationResults] = useState({
    consultation: {
      hits: []
    },
    listing: {
      hits: []
    }
  })
  const [isConsultationResultsRendered, setIsConsultationResultsRendered] = useState(false)
  const [isLocationResultsRendered, setIsLocationResultsRendered] = useState(false)
  const { classes } = useDesktopInputFieldsStyles()

  useEffect(() => {
    if (locationInputValue.length === 0) {
      setIsLocationResultsRendered(false)
    }
  }, [locationInputValue])

  const locationResultItemClickHandler = (value) => {
    setLocationInputValue(value)
    setIsLocationResultsRendered(false)
  }

  const consultationResultItemClickHandler = (value) => {
    setConsultationInputValue(value.name)
    setIsConsultationResultsRendered(false)
    dispatch(setConsultationFilterOption(value))
    trackEvent(AnalyticsEvent.consultationSearchTapped)
  }

  const useMyLocationButtonClickHandler = async () => {
    await onUseMyLocationClick()
  }

  const consultationFocusHandler = () => {
    setIsConsultationResultsRendered(true)
  }

  const locationFocusHandler = async () => {
    const locations = await getLocationsByName(locationInputValue)
    setLocationResults(locations)
    if (locationInputValue.length > 0) {
      setIsLocationResultsRendered(true)
    }
  }

  const consultationBlurHandler = (value) => {
    setIsConsultationResultsRendered(false)

    if (value.length === 0) {
      setConsultationInputValue(consultationFilterOption.name)
    }
  }

  const locationBlurHandler = () => {
    setIsLocationResultsRendered(false)
  }

  const mouseDownHandler = (event) => {
    event.preventDefault()
  }

  const getLocations = async (value) => {
    const locations = await getLocationsByName(value)
    setLocationResults(locations)
  }

  const locationInputChangeHandler = (value) => {
    if (onLocationInputChange) {
      onLocationInputChange(value)
    }

    if (value !== '') {
      getLocations(value)
      setIsLocationResultsRendered(true)
    } else {
      setIsLocationResultsRendered(false)
    }
  }

  const consultationInputChangeHandler = (value) => {
    onConsultationInputChange(value)
    setIsConsultationResultsRendered(true)
  }

  useEffect(() => {
    if (consultationInputValue === '') {
      dispatch(setConsultationFilterOption({ name: '', category: '', id: '' }))
    }
  }, [consultationInputValue])

  return (
    <Box className={classes.container}>
      <Box style={{ position: 'relative' }}>
        <InstantSearch searchClient={algoliaClient} indexName="Consultation">
          <ConsultationInputField
            value={consultationInputValue}
            setValue={setConsultationInputValue}
            placeholder="Search"
            onChange={consultationInputChangeHandler}
            onFocus={consultationFocusHandler}
            onBlur={consultationBlurHandler}
            setConsultationResults={setConsultationResults}
          />
          {isConsultationResultsRendered && (
            <Paper className={classes.resultListContainer} onMouseDown={mouseDownHandler}>
              <ConsultationResultList
                results={consultationResults}
                consultationInputValue={consultationInputValue}
                onItemClick={consultationResultItemClickHandler}
                platform="desktop"
              />
            </Paper>
          )}
        </InstantSearch>
      </Box>
      <Box style={{ position: 'relative' }}>
        <LocationInputField
          value={locationInputValue}
          setValue={setLocationInputValue}
          placeholder="Search by location"
          onChange={locationInputChangeHandler}
          onFocus={locationFocusHandler}
          onBlur={locationBlurHandler}
        />
        {isLocationResultsRendered && (
          <Paper className={classes.resultListContainer} onMouseDown={mouseDownHandler}>
            <LocationResultList
              results={locationResults}
              locationInputValue={locationInputValue}
              onItemClick={locationResultItemClickHandler}
              onUseMyLocationClick={useMyLocationButtonClickHandler}
              isFetchLocationError={isFetchLocationError}
            />
          </Paper>
        )}
      </Box>
    </Box>
  )
}
